.input-slider,
.resizableChild {
  width: calc(33% - 4.12px);
}
  
.field-block .resizableChild:first-child:nth-last-child(2),
.field-block .resizableChild:first-child:nth-last-child(2) ~ .resizableChild,
.field-block .resizableChild:first-child:nth-last-child(4),
.field-block .resizableChild:first-child:nth-last-child(4) ~ .resizableChild {
  width: calc(50% - 8px);
}

.input-slider,
.MuiFormControlLabel-root {
  border: 1px solid rgba(0, 0, 0, 0.25);
  color: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  padding: 0 14px;
  height: 56px;
  display: inline-block;
  margin: 8px 4px;
}

.input-slider {
  padding: 0 20px;
  position: relative;
  z-index: 0;
  top: -1px;
}

.input-slider .MuiSlider-root {
  transform: translateY(-50%);
  top: 50%;
}

.input-slider label {
  color: rgba(0, 0, 0, 0.6);
  font-family: Montserrat;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  padding: 0;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 32px);
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(14px, -9px) scale(0.75);
  z-index: -1;
  pointer-events: auto;
  user-select: none;
  background: #fff;
  padding: 0 5px;
}

.input-label {
  white-space: pre-wrap;
}

input::-webkit-outer-spin-button, 
input::-webkit-inner-spin-button { margin-left: 10px; } 

input[type=number] {
  text-align: right !important;
}

@media only screen and (max-width: 1200px) {
  .input-slider,
  .resizableChild {
    width: calc(50% - 8px);
  }
}

@media only screen and (max-width: 900px) {
  .input-slider,
  .MuiFormControl-root,
  .MuiFormControlLabel-root,
  .field-block .resizableChild:first-child:nth-last-child(2),
  .field-block .resizableChild:first-child:nth-last-child(2) ~ .resizableChild,
  .field-block .resizableChild:first-child:nth-last-child(4),
  .field-block .resizableChild:first-child:nth-last-child(4) ~ .resizableChild {
    width: calc(100% - 8px);
  }
}

.input-largetext,
.field-block .resizableChild:only-child {
  width: calc(100% - 8px);
}

tr .input-slider,
tr .resizableChild,
.MuiFormControlLabel-root .MuiTypography-root {
  width: 100% !important;
}

tr th:first-child,
tr td:first-child {
  margin-left: 0;
  padding-left: 0;
}

tr th:first-child *,
tr td:first-child * {
  margin-left: 0;
}

tr th:last-child,
tr td:last-child {
  margin-right: 0;
  padding-right: 0;
}

tr th:last-child *,
tr td:last-child * {
  margin-right: 0;
}