@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
html {
  height: 100%;
  margin: 0;
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-optical-sizing: auto;
  font-style: normal;
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
  margin: 0;
  background-color: #f0f0f0;
}

.MuiButtonBase-root.dashboard-button {
  width: Calc(50% - 5px);
  aspect-ratio: 5/1;
  border-radius: 9px;
  line-height: 25px;
  margin-bottom: 10px;
  font-size: 2vw;
  text-transform: capitalize;
}

.MuiButtonBase-root.dashboard-button:nth-child(even) {
  margin-left: 10px;
}

.MuiButtonBase-root.dashboard-button:nth-child(odd):last-child {
  width: Calc(100%);
  aspect-ratio: 10/1;
}

.MuiButtonBase-root.dashboard-button .material-icons,
.MuiButtonBase-root.dashboard-button .MuiSvgIcon-root {
  height: 2.5vw;
  width: 2.5vw;
}

.uniqueError-true fieldset {
  border-color: #f00 !important;
}